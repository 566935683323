const $ = jQuery.noConflict();

const $window = $(window);
const $body = $('body');
const $header = $('#header');
const $logo_slider = $('.logo-slider-wrapper');
const $menu_project = $('.menu-project');
const $menu_project_accordion = $('.menu-project .accordion-menu');
const $content_project = $('.content-project');
const $content_project_row = $('#content-project > .content-wrapper > .row');
const $preload_ajax = $('.preload-ajax');
const $dropdown_label = $('.dropdown-label');

let windoww = 0;
let windowh = 0;

let headerH = 0;
let menuWidth = 0;
let menuHeight = 0;
let windowHeight = 0;

let projectHeight = 0;

if ($menu_project.length) {
    headerH = $header.outerHeight();
    menuWidth = $menu_project.width();
    menuHeight = $menu_project_accordion.height();
    windowHeight = $window.height() - headerH - 60;
}

function fitAll() {
    /*--------------------------------------------------------- GENERAL ---------------------------------------------------------*/
    windoww = $window.width();
    windowh = $window.height();

    /*--------------------------------------------------------- BACKGROUND VIDEO ---------------------------------------------------------*/
    if ($('.bg-section-video video').length) {
        $('.bg-section-video').each(function () {
            const wrapperw = $(this).width(),
                videow = $(this).find('video').width();
            if (wrapperw > videow) {
                $(this).addClass('width').removeClass('height');
            } else {
                $(this).removeClass('width').addClass('height');
            }
        });
    }

    /*--------------------------------------------------------- PROJECT MENU ---------------------------------------------------------*/
    if ($menu_project.length && $window.width() > 1199) {

        $menu_project_accordion.css('width', menuWidth);

        if (menuHeight > windowHeight) {
            $menu_project.addClass('scroll-menu');
            $menu_project_accordion.css('height', windowHeight);
        } else {
            $menu_project.removeClass('scroll-menu');
            $menu_project_accordion.css('height', 'auto');
        }
    }

    /*--------------------------------------------------------- HEADER HEIGHT ---------------------------------------------------------*/
    if ($window.scrollTop() === 0) {
        headerH = $header.outerHeight();
        $body.css('padding-top', headerH);
    }
}

let previousScroll = 0;

function onScroll() {
    /*--------------------------------------------------------- PROJECT MENU ---------------------------------------------------------*/
    if ($menu_project.length && $window.width() > 1199) {

        headerH = $header.outerHeight();
        windowHeight = $window.height() - headerH - 60;
        projectHeight = $content_project_row.height() + $content_project_row.offset().top - windowHeight;

        if (($menu_project.scrollTop() + $menu_project.offset().top) < $window.scrollTop() + 30) {
            $body.addClass('project-menu-top');
        } else {
            $body.removeClass('project-menu-top');
        }

        if ($window.scrollTop() > projectHeight) {
            $body.addClass('project-menu-bottom');
        } else {
            $body.removeClass('project-menu-bottom');
        }

    }

    /*--------------------------------------------------------- PAGE SCROLLED ---------------------------------------------------------*/
    headerH = $header.outerHeight();
    if ($window.scrollTop() > headerH) {
        $body.addClass('page-scrolled');
    } else {
        $body.removeClass('page-scrolled');
    }

    /*--------------------------------------------------------- END PAGE ---------------------------------------------------------*/
    if ($window.scrollTop() + $window.height() >= $(document).height()) {
        $body.addClass('end-page');
    } else {
        $body.removeClass('end-page');
    }

    /*--------------------------------------------------------- PAGE SCROLLING TO TOP / BOTTOM ---------------------------------------------------------*/
    let currentScroll = $window.scrollTop();
    if (currentScroll > previousScroll && currentScroll > 0 || $body.hasClass('loading')) {
        $body.addClass('scrolling-to-bottom').removeClass('scrolling-to-top');
    } else {
        $body.addClass('scrolling-to-top').removeClass('scrolling-to-bottom');
    }
    previousScroll = currentScroll;

    /*--------------------------------------------------------- SCROLL EFFECT ---------------------------------------------------------*/
    const $scroll_anim = $('.scroll-anim');
    if ($scroll_anim.length) {
        $scroll_anim.each(function () {
            if (($(this).scrollTop() + $(this).offset().top) < ($window.scrollTop() + $window.height() - ($window.height() / 6))) {
                $(this).addClass('scroll-anim-active');
            } else {
                $(this).removeClass('scroll-anim-active');
            }
        });
    }

    /*--------------------------------------------------------- PRELOAD IMAGES ---------------------------------------------------------*/
    const $lazy_bg = $('[data-bg]:not(.swiper-lazy)');
    if ($lazy_bg.length) {
        $lazy_bg.each(function () {
            if (($(this).scrollTop() + $(this).offset().top) < ($window.scrollTop() + $window.height() + ($window.height() / 2))) {
                if (!($(this).hasClass('loaded'))) {
                    const src = $(this).attr('data-bg');
                    $(this).hide().addClass('loaded').css('background-image', 'url("' + src + '")').removeAttr('data-bg').fadeIn(350);
                }
            }
        });
    }

    const $lazy_img = $('img[data-src]:not(.swiper-lazy)');
    if ($lazy_img.length) {
        $lazy_img.each(function () {
            if (($(this).scrollTop() + $(this).offset().top) < ($window.scrollTop() + $window.height() + ($window.height() / 2))) {
                if (!($(this).hasClass('loaded'))) {
                    const src = $(this).attr('data-src');
                    $(this).hide().addClass('loaded').attr('src', src).removeAttr('data-src').fadeIn(350);
                }
            }
        });
    }

    /*--------------------------------------------------------- PLAY VIDEOS ---------------------------------------------------------*/
    const $video = $('video');
    if ($video.length) {
        $video.each(function () {
            if (($(this).scrollTop() + $(this).offset().top) < ($window.scrollTop() + $window.height() + $window.height())) {
                if (!($(this).hasClass('play'))) {
                    $(this).addClass('play').show();
                    $(this).get(0).play();
                }
            }
        });
    }
}

/*--------------------------------------------------------- MENU BURGER ---------------------------------------------------------*/
$body.on('click touch tap', '#menu-burger-wrapper', function () {
    if ($body.hasClass('menu-opened-step2')) {
        $body.removeClass('menu-opened-step2');
        setTimeout(function () {
            $body.removeClass('menu-opened-step1');
        }, 500);
    } else {
        $body.addClass('menu-opened-step1');
        setTimeout(function () {
            $body.addClass('menu-opened-step2');
        }, 50);
    }
});

function beforeTransitionAjax() {
    $body.addClass('loading');

    if ($content_project.length) {
        $content_project.slideUp(300);
    }
}

function transitionAjax() {
    if ($content_project.length && $preload_ajax.length) {
        $body.removeClass('loading');
        $content_project.remove();
        $preload_ajax.attr('class', 'content-project').slideDown(300);
        $('#contains-project').prepend('<div class="preload-ajax" style="display:none;"></div>');
    }

    const $project_anchor = $('#project-anchor');
    if ($project_anchor.length) {
        const $main = $('html, body');
        let destination = $project_anchor.offset().top;
        $main.on('scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove', function () {
            $main.stop();
        });
        $main.animate({scrollTop: destination}, 500, function () {
            $main.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
        });
    }
}

function openMobileMenuProject() {
    if ($window.width() <= 1199) {
        $('#project-intro').slideDown(300);
        $('#project-mobile-menu').slideDown(300);
        $('#content-project .accordion-menu > .accordion-item > header').slideUp(300);
    }
}

function closeMobileMenuProject() {
    if ($window.width() <= 1199) {
        $('#project-intro').slideUp(300);
        $('#project-mobile-menu').slideUp(300);
        $('#content-project .accordion-menu > .accordion-item > header').slideDown(300);
    }
}

function contactForms() {
    /*--------------------------------------------------------- FORM ---------------------------------------------------------*/
    $('.row-form input, .row-form textarea, .row-form select').each(function () {
        if ($(this).val() === '') {
            $(this).closest('.row-form').removeClass('focus-label');
        } else {
            $(this).closest('.row-form').addClass('focus-label');
        }
    });

    $body.on('focusin change input paste', '.row-form input, .row-form textarea, .row-form select', function () {
        $(this).closest('.row-form').addClass('focus-label');
    });

    $body.on('focusout change input paste', '.row-form input, .row-form textarea', function () {
        if ($(this).val() === '') {
            $(this).closest('.row-form').removeClass('focus-label');
        }
    });

    $body.on('focusout change', '.row-form select', function () {
        if ($(this).find('option:selected').text() === '') {
            $(this).closest('.row-form').removeClass('focus-label');
        }
    });

    const $row_form_select_option = $('.row-form select option');
    if ($row_form_select_option.length) {
        $row_form_select_option.each(function () {
            if ($(this).text() === '' || $(this).text() === '—') {
                $(this).attr('disabled', 'disabled');
            }
        });
        $('.row-form select').blur();
    }
}

if ($('.row-form-checkbox').length) {
    $('.row-form-checkbox input').each(function () {
        if ($(this).is(":checked")) {
            $(this).closest('.row-form-checkbox').addClass('checked');
        } else {
            $(this).closest('.row-form-checkbox').removeClass('checked');
        }

        $(this).change(function () {
            const $thischeckchange = $(this);

            if ($thischeckchange.is(":checked")) {
                $thischeckchange.closest('.row-form-checkbox').addClass('checked');
            } else {
                $thischeckchange.closest('.row-form-checkbox').removeClass('checked');
            }
        });
    });
}

if ($('.row-form-radio').length) {
    $('.row-form-radio input').each(function () {
        if ($(this).is(":checked")) {
            $(this).closest('.row-form-radio').addClass('checked');
            $(this).closest('.row-form-radio').find('label').removeClass('checked');
            setTimeout(function () {
                $(this).parent('label').addClass('checked');
            }, 100);
        } else {
            $(this).closest('.row-form-radio').removeClass('checked');
            $(this).parent('label').removeClass('checked');
        }

        $(this).change(function () {
            const $thisradiochange = $(this);

            if ($thisradiochange.is(":checked")) {
                $thisradiochange.closest('.row-form-radio').addClass('checked');
                $thisradiochange.closest('.row-form-radio').find('label').removeClass('checked');
                setTimeout(function () {
                    $thisradiochange.parent('label').addClass('checked');
                }, 100);
            } else {
                $thisradiochange.closest('.row-form-radio').removeClass('checked');
                $thisradiochange.parent('label').removeClass('checked');
            }
        });
    });
}

function titleFormation() {
    let title = $('.title-formation').data('title');
    $('input[name="formation"]').val(title);
}

$window.load(function () {
    /*--------------------------------------------------------- LOGO SLIDER ---------------------------------------------------------*/
    if ($logo_slider.length) {
        let sliderH = 0;

        $logo_slider.each(function () {

            $(this).find('.logo-slider').each(function () {

                if ($(this).height() > sliderH) {
                    sliderH = $(this).height();
                }

            });

            $(this).css('height', sliderH);

        });
    }
});


$(document).ready(function () {
    /*--------------------------------------------------------- GENERAL ---------------------------------------------------------*/
    $window.on('scroll', function () {
        onScroll();
    });

    $window.on('resize', function () {
        fitAll();
        onScroll();
    });

    fitAll();
    onScroll();

    /*--------------------------------------------------------- FANCYBOX ---------------------------------------------------------*/
    let smallBtn = '<button data-fancybox-close class="fancybox-button fancybox-close-small" title="{{CLOSE}}"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 44 44" style="enable-background:new 0 0 44 44;" xml:space="preserve"><path class="st0" d="M22,0.5C10.1,0.5,0.5,10.1,0.5,22c0,11.9,9.7,21.5,21.5,21.5S43.5,33.9,43.5,22C43.5,10.1,33.9,0.5,22,0.5z M22,40.5C11.8,40.5,3.5,32.2,3.5,22S11.8,3.5,22,3.5c10.2,0,18.5,8.3,18.5,18.5S32.2,40.5,22,40.5z M24.6,22.1l5.5,5.5l-2.1,2.1 l-5.5-5.5L17,29.7l-2.1-2.1l5.5-5.5l-5.4-5.4l2.1-2.1l5.4,5.4l5.4-5.5l2.1,2.1L24.6,22.1z"/></svg></button>';
    let $fancybox_formation = $('.formation-items [data-fancybox]');
    if ($fancybox_formation.length) {

      
        $('.preview2').on("click", function (e) {
          e.preventDefault(); // avoids calling preview.php
          $.ajax({
            type: "POST",
            cache: false,
            crossDomain: true,
            headers: {
              "accept": "application/json",
              "Access-Control-Allow-Origin":"*"
            },
            url: this.href, // preview.php
            data: $("#postp").serializeArray(), // all form fields
            
            success: function (data) {
              // on success, post (preview) returned data in fancybox
              $fancybox_formation.fancybox(data, {
                // fancybox API options
                fitToView: false,
                width: 905,
                height: 505,
                autoSize: false,
                closeClick: false,
                openEffect: 'none',
                closeEffect: 'none'
              }); // fancybox
            } // success
          }); // ajax
        }); // on
      

        $fancybox_formation.fancybox({
            arrows: false,
            infobar: false,
            touch: false,
            autoFocus: false,
            hash: false,
            btnTpl: {
                smallBtn: smallBtn
            },
            beforeShow: function(instance, slide) {
                var uniqueSlug = slide.opts.$orig.attr('data-src');
                if ( uniqueSlug ) {
                    window.location.hash = uniqueSlug;
                }
            },
            afterShow: function () {
                contactForms();
                titleFormation();
            },
            beforeClose: function() {
                history.replaceState(null, null, ' ');
            },
        });

        let currentHash = window.location.hash;
        if ( currentHash !== '' && $('.formation-items [data-fancybox][data-src="' + currentHash + '"]').length ) {
            $('.formation-items [data-fancybox][data-src="' + currentHash + '"]').eq(0).focus().click();
        }
    }

    let fancybox_faq = $('[data-fancybox*="faq"]');
    if (fancybox_faq.length) {
        fancybox_faq.fancybox({
            arrows: false,
            infobar: false,
            touch: false,
            btnTpl: {
                smallBtn: smallBtn
            },
        });
    }

    let fancybox_iframe = $('[data-fancybox][data-type="iframe"]');
    if (fancybox_iframe.length) {
        fancybox_iframe.fancybox({
            arrows: false,
            infobar: false,
            touch: false,
            smallBtn: true,
            btnTpl: {
                smallBtn: smallBtn
            },
        });
    }

    /*--------------------------------------------------------- CF7 FORMATION ---------------------------------------------------------*/
    if ($('input[name="formation"]')) {
        titleFormation();
    }

    /*--------------------------------------------------------- LOADING PAGE ---------------------------------------------------------*/
    $body.addClass('page-load-step1');
    setTimeout(function () {
        $body.addClass('page-load-step2');
    }, 500);
    setTimeout(function () {
        $body.addClass('page-load-step3');
    }, 1000);

    /*--------------------------------------------------------- SCROLL TO ---------------------------------------------------------*/
    $body.on('click', '.scrollto', function (e) {
        const target = $(this).attr('href');
        const main = $('html, body');
        let destination = $(target).offset().top;
        let currentPos = $window.scrollTop();
        if (destination !== currentPos) {
            $(main).on('scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove', function () {
                main.stop();
            });
            main.animate({scrollTop: destination}, 500, function () {
                main.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
            });
        }

        e.preventDefault();
    });


    /*--------------------------------------------------------- FORM ---------------------------------------------------------*/
    if ($('.row-form').length) {
        contactForms();

        document.addEventListener('wpcf7mailsent', function () {
            setTimeout(function () {
                contactForms();
            }, 500);
        }, false);
    }

    /*--------------------------------------------------------- ACCORDION ---------------------------------------------------------*/
    $('.accordion-wrapper header').on('click', function () {
        if ($(this).closest('.accordion-wrapper').children('.accordion-item').children('main').is(':visible')) {
            $(this).closest('.accordion-wrapper').children('.accordion-item').children('header').not(this).next('main').slideUp(300);
            $(this).closest('.accordion-wrapper').children('.accordion-item').children('header').not(this).parent('.accordion-item').removeClass('active');
        }

        if ($(this).hasClass('header-project')) {
            if (!$(this).parent().hasClass('active')) {
                $(this).closest('.accordion-wrapper').children('.accordion-item').children('main').slideUp(300);
                $(this).closest('.accordion-wrapper').children('.accordion-item').removeClass('active');

                $(this).next('main').slideDown(300);
                $(this).parent('.accordion-item').addClass('active');
            }
        } else {
            if ($(this).next('main').is(':visible')) {
                $(this).next('main').slideUp(300);
                $(this).parent('.accordion-item').removeClass('active');
            } else {
                $(this).next('main').slideDown(300);
                $(this).parent('.accordion-item').addClass('active');
            }
        }
    });

    /*--------------------------------------------------------- DROPDOWN ---------------------------------------------------------*/
    $body.on('click touchend', function () {
        if ($dropdown_label.length) {
            $dropdown_label.each(function () {
                if ($(this).next('.dropdown-menu').is(':visible')) {
                    $(this).next('.dropdown-menu').slideUp(300);
                    $(this).parent().removeClass('active');
                }
            });
        }
    });

    $dropdown_label.on('click touchend', function (e) {
        if ($(this).next('.dropdown-menu').is(':visible')) {
            $(this).next('.dropdown-menu').slideUp(300);
            $(this).parent().removeClass('active');
        } else {
            $(this).next('.dropdown-menu').slideDown(300);
            $(this).parent().addClass('active');
        }

        e.stopPropagation();
        e.preventDefault();
    });

    $('.filter-item').on('click touchend', function () {
        $(this).closest('.dropdown-menu').find('.filter-item').parent('li').removeClass('current');
        $(this).parent('li').addClass('current');
    });


    /*--------------------------------------------------------- MENU HOVER ---------------------------------------------------------*/
    let menuTimeoutIn;
    let menuTimeoutOut1;
    let menuTimeoutOut2;
    $body.on('mouseenter', '.menu-item-has-children', function () {
        clearTimeout(menuTimeoutOut1);
        clearTimeout(menuTimeoutOut2);
        const $this = $(this);
        $this.addClass('hover-step1');
        menuTimeoutIn = setTimeout(function () {
            $this.addClass('hover-step2');
        }, 10);
    });

    $body.on('mouseleave', '.menu-item-has-children', function () {
        clearTimeout(menuTimeoutIn);

        const $this = $(this);
        menuTimeoutOut1 = setTimeout(function () {
            $this.removeClass('hover-step2');
        }, 800);
        menuTimeoutOut2 = setTimeout(function () {
            $this.removeClass('hover-step1');
        }, 1100);
    });


    /*--------------------------------------------------------- LOGOS SLIDER ---------------------------------------------------------*/
    if ($logo_slider.length) {
        let sliderNext = 2;
        $logo_slider.each(function () {
            const $this = $(this);
            let sliderInterval = $this.data('interval');
            if (sliderInterval === undefined) {
                sliderInterval = 300;
            }
            let count = $this.children('.logo-slider').size();
            let loop = setInterval(function () {
                if (sliderNext > count) {
                    sliderNext = 1;
                }
                $this.children('.logo-slider').removeClass('active').fadeOut(300);
                $this.children('.logo-slider[data-slide="' + sliderNext + '"]').addClass('active').fadeIn(300);
                sliderNext = sliderNext + 1;
            }, sliderInterval);
        });
    }


    /*--------------------------------------------------------- MOSAIC PRESS ---------------------------------------------------------*/
    const $mosaic = $('.mosaic');
    if ($mosaic.length) {
        if ($window.width() > 767) {
            $mosaic.Mosaic({
                outerMargin: 0,
                innerGap: 30,
                maxRowHeight: 400,
                refitOnResize: true,
                refitOnResizeDelay: false,
                maxRowHeightPolicy: 'tail',
                highResImagesWidthThreshold: 200,
                responsiveWidthThreshold: 200
            });
        }

        const $press_img = $('.press img[data-url]');
        if ($press_img.length) {
            $press_img.each(function () {
                const url = $(this).data('url');
                if (url) {
                    $(this).wrap('<a href="' + url + '" target="_blank">');
                }
            });
        }
    }


    /*--------------------------------------------------------- MOSAIC PRESS ---------------------------------------------------------*/
    $body.on('touchstart tap', '#team-grid-masonry .team-card-wrapper', function () {
        if ($(this).hasClass('hover')) {
            $(this).removeClass('hover');
        } else {
            $(this).addClass('hover');
            $('#team-grid-masonry .team-card-wrapper').not(this).removeClass('hover');
        }
    });


    /*--------------------------------------------------------- FORMATION DATES ---------------------------------------------------------*/
    $body.on( 'click', '.date-formation-wrapper .show-more', function(e) {
        $(this).closest('.date-formation-wrapper').find('ul li').slideDown(300);
        $(this).slideUp(300);

        e.preventDefault();
    });


    /*--------------------------------------------------------- AJAX ---------------------------------------------------------*/
    $body.on('click', '.project-box', function (e) {
        const target = $(this).data('url');
        // const archive = $('[data-url-archive]').data('url-archive');

        if (target !== window.location.href) {
            beforeTransitionAjax();

            $body.addClass('single');
            $body.removeClass('archive');

            closeMobileMenuProject();

            $preload_ajax.load(target + ' .content-project > *', function (responseTxt, statusTxt) {

                if (statusTxt === 'success') {

                    transitionAjax();

                    if (history.pushState) {
                        history.pushState({}, null, target);
                    }

                }
            });
        }

        e.preventDefault();
    });

    $window.on('popstate', function () {
        beforeTransitionAjax();

        let target = decodeURIComponent(window.location.href),
            archive = $('[data-url-archive]').data('url-archive');

        if (target === archive) {
            $body.removeClass('single');
            $body.addClass('archive');

            openMobileMenuProject();
        } else {
            $body.addClass('single');
            $body.removeClass('archive');

            closeMobileMenuProject();
        }

        $('.project-box[data-url="' + target + '"').find('header').click();

        $preload_ajax.load(target + ' .content-project > *', function (responseTxt, statusTxt) {
            if (statusTxt === 'success') {
                transitionAjax();

                if (target === archive) {
                    if ($window.width() <= 1199) {
                        $content_project.hide();
                    }
                }
            }
        });
    });

});










jQuery(document).ready(function () {

  jQuery('.preview3').on("click", function (e) {
    /* jQuery.ajax({
      type: "post",
      //dataType: "json",
      url: my_ajax_object.ajax_url,
      data: {action:'my_special_ajax_call_enroll_cours'},
      success: function(msg){
          console.log(msg);
      }
    }); */


    //const el = this.document.querySelector('.preview3');
    //console.log();
    /*el.forEach(function (element, index) {
      const formation_id = element.dataset.formation;

      console.log(formation_id);
    });*/

    const formation_id = e.currentTarget.dataset.formation;
    

    let smallBtn = '<button data-fancybox-close class="fancybox-button fancybox-close-small" title="{{CLOSE}}"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 44 44" style="enable-background:new 0 0 44 44;" xml:space="preserve"><path class="st0" d="M22,0.5C10.1,0.5,0.5,10.1,0.5,22c0,11.9,9.7,21.5,21.5,21.5S43.5,33.9,43.5,22C43.5,10.1,33.9,0.5,22,0.5z M22,40.5C11.8,40.5,3.5,32.2,3.5,22S11.8,3.5,22,3.5c10.2,0,18.5,8.3,18.5,18.5S32.2,40.5,22,40.5z M24.6,22.1l5.5,5.5l-2.1,2.1 l-5.5-5.5L17,29.7l-2.1-2.1l5.5-5.5l-5.4-5.4l2.1-2.1l5.4,5.4l5.4-5.5l2.1,2.1L24.6,22.1z"/></svg></button>';
    let $fancybox_formation = $('.formation-items [data-fancybox]');
    //console.log(my_ajax_object.ajax_url);
    //var prevPostId;
    jQuery.post( my_ajax_object.ajax_url, {
      action: "get_prev",
      formation_id: formation_id
    }, function( data ) {
      console.log( "Data Loaded: " + data );
      //$.fancybox(data);
      console.log(formation_id+"---js");
      $.fancybox.open(data);


      /* $fancybox_formation.fancybox(data, {
        // fancybox API options
        fitToView: false,
        width: 905,
        height: 505,
        autoSize: false,
        closeClick: false,
        openEffect: 'none',
        closeEffect: 'none'
      }); */
    });/* function(prevPostId) {
      console.log('12');
      console.log(prevPostId)
    }); */

  });



});